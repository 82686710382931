@import '@sass/_variables.scss';

html,
body {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.25;
  margin: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;   /*See https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit*/
  padding: 0;
}

html {
  box-sizing: border-box;
}

* {

  &,
  &::before,
  &::after {
    box-sizing: inherit;
    position: relative;
  }
}

a {

  &,
  &:active,
  &:focus,
  &:hover,
  &:visited {
    color: inherit;
    text-decoration: underline;
  }
}

.body-content {

  &.fixed {
    flex-shrink: 0;
  }

  &.variable {
    flex: 1 0 auto;
  }
}


#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;   /*See https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit*/
}

.main {
  padding-bottom: 5em;
}

.section {
  display: block;
  margin: 0 auto;
}

.content-block {
  margin: 0 auto;

  @media (max-width: $small-screen-max) {
    max-width: $tablet-portrait-max * .75;
    width: 90%;
  }

  @media (min-width: $small-screen-max + 1) {
    max-width: $small-screen-max;
  }
}

.ornament {
  border-style: solid;
  height: 2em;
  position: absolute;
  width: 2em;

  &.top {
    border-color: $dark;
    top: 1em;

    &.left {
      border-width: 1px 0 0 1px;
    }

    &.right {
      border-width: 1px 1px 0 0;
    }
  }

  &.bottom {
    border-color: $white;
    bottom: 1em;

    &.left {
      border-width: 0 0 1px 1px;
    }

    &.right {
      border-width: 0 1px 1px 0;
    }
  }

  &.left {
    left: 1em;
  }

  &.right {
    right: 1em;
  }
}

.error {
  color: red;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}
