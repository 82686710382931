
@import '@sass/_mixins.scss';
@import '@sass/_variables.scss';

.main-footer {
  background-color: $dark;
  color: $white;
  display: block;
  padding: 3em 3em 3em 3em;

  .flex-wrapper {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    @media (max-width: $mobile-footer-max) {
      flex-direction: column;
    }
  }

  .link-list {
    @include unlist;

    @media (max-width: $mobile-footer-max) {
      border-top: 1px solid $white;
      padding-top: 2em;
      text-align: left;
      width: 100%;
    }

    @media (min-width: $mobile-footer-max + 1) {
      text-align: right;
    }
  }

  .footer-link {
    display: block;
    text-decoration: none;
    text-transform: uppercase;

    @media (max-width: $mobile-footer-max) {
      margin-bottom: 1.5em;
    }

    @media (min-width: $mobile-footer-max + 1) {
      margin-bottom: .5em;
    }
  }

  .company-address {
    font-style: normal;

    @media (max-width: $mobile-footer-max) {
      margin-bottom: 2em;
    }

    p {
      text-align: left;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {

        @media (max-width: $mobile-footer-max) {
          margin-bottom: 0;
        }
      }
    }
  }
}
