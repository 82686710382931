
@import '@sass/_variables.scss';
@import '@sass/_mixins.scss';
@import '@sass/_button.scss';

.cookie-consent {
  background-color: $white-back;
  border-top: 1px solid $dark;
  bottom: 0;
  left: 0;
  max-height: 100%;
  overflow-y: auto;
  position: fixed;
  width: 100%;
  z-index: 200;

  table {
    border-collapse: collapse;
    margin: 2.5rem auto 3rem;

    @media (max-width: $mobile-max) {
      display: block;
    }

    thead {
      @media (max-width: $mobile-max) {
        display: none;
      }
    }

    th,
    td {
      padding: .5rem .25rem;
      text-align: left;
      vertical-align: top;

      @media (max-width: $mobile-max) {
        display: block;
      }
    }

    td {

      &::before {

        @media (max-width: $mobile-max) {
          font-weight: 600;
        }
      }

      &:nth-child(1) {

        &::before {

          @media (max-width: $mobile-max) {
            content: 'Cookie Name: ';
          }
        }
      }

      &:nth-child(2) {

        &::before {

          @media (max-width: $mobile-max) {
            content: 'Expiration Time: ';
          }
        }
      }

      &:nth-child(3) {

        &::before {

          @media (max-width: $mobile-max) {
            content: 'Description: ';
          }
        }
      }
    }

    tr {
      border-bottom: 1px solid $white;

      @media (max-width: $mobile-max) {
        border-bottom: 1px solid $dark;
        display: block;
      }
    }
  }

  .button {

    @media (max-width: $mobile-max) {
      display: block;
      margin-bottom: 1.5em;
      width: 100%;
    }

    @media (min-width: $mobile-max + 1) {
      margin: 0 4px;
    }
  }

  .liner {
    padding: 2rem;

    p {

      &:first-child {
        margin-bottom: 2em;
      }
    }
  }
}
