
.butterfly-animation {
  display: block;
  max-height: 342px;
  max-width: 548px;
  position: relative;
  vertical-align: top;

  .blueprint {
    height: auto;
    max-height: 342px;
    max-width: 548px;
    /*opacity: 0.5;*/
    visibility: hidden;
    width: 100%;
  }

  .a {
    left: 38.8%;
    position: absolute;
    top: -1.5%;
    width: 22.75%;
  }

  .ai {
    position: absolute;
    top: 0;
    width: 49%;

    &.l {
      left: 0;
    }

    &.r {
      right: 0;
    }
  }

  .w {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;

    &.l {
      left: 0;
    }

    &.r {
      right: 0;
    }
  }

  .cw {
    height: auto;
    position: absolute;
    width: 100%;
  }

  .c1 {
    top: 0;
    width: 14.5%;

    &.l {
      left: 0;
    }

    &.r {
      right: 0;
    }
  }

  .c2 {
    top: 8.5%;
    width: 11.6%;

    &.l {
      left: 13.6%;
    }

    &.r {
      right: 13.6%;
    }
  }

  .c3 {
    top: 19.8%;
    width: 13.3%;

    &.l {
      left: 22.4%;
    }

    &.r {
      right: 22.4%;
    }
  }

  .c4 {
    top: 33.6%;
    width: 12.3%;

    &.l {
      left: 32.3%;
    }

    &.r {
      right: 32.3%;
    }
  }


  .c5 {
    top: 25.6%;
    width: 14.6%;

    &.l {
      left: 8.7%;
    }

    &.r {
      right: 8.7%;
    }
  }

  .c6 {
    top: 40.6%;
    width: 14.4%;

    &.l {
      left: 19.6%;
    }

    &.r {
      right: 19.6%;
    }

  }

  .c7 {
    top: 52.6%;
    width: 11.6%;

    &.l {
      left: 32.8%;
    }

    &.r {
      right: 32.8%;
    }
  }

  .c8 {
    top: 56.4%;
    width: 6.4%;

    &.l {
      left: 15.1%;
    }

    &.r {
      right: 15.1%;
    }
  }

  .c9 {
    top: 61.7%;
    width: 9.3%;

    &.l {
      left: 25.1%;
    }

    &.r {
      right: 25.1%;
    }
  }

  .c10 {
    top: 70.4%;
    width: 6.4%;

    &.l {
      left: 34.7%;
    }

    &.r {
      right: 34.7%;
    }
  }

  .c11 {
    top: 65.4%;
    width: 11.9%;

    &.l {
      left: 14.5%;
    }

    &.r {
      right: 14.5%;
    }
  }

  .c12 {
    top: 75.5%;
    width: 14.4%;

    &.l {
      left: 23.5%;
    }

    &.r {
      right: 23.5%;
    }
  }

  .c13 {
    left: 47.2%;
    top: 32.5%;
    width: 5.8%;
  }

  .c14 {
    left: 42.8%;
    top: 41.3%;
    width: 14.6%;
  }

  .c15 {
    left: 46.2%;
    top: 64.1%;
    width: 7.8%;
  }

  .c16 {
    left: 47.5%;
    top: 76.2%;
    width: 5.2%;
  }

  .c17 {
    left: 48%;
    top: 84.1%;
    width: 4.2%;
  }
}
