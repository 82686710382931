
@import '@sass/_variables.scss';

.main-header {

  @media (max-width: $mobile-menu-max) {
    padding: 2.5em 0;
  }

  @media (min-width: $mobile-menu-max + 1) {
    padding: 2.5em 0 1em;
  }

  .butterfly-animation {
    margin: 0 auto 1em;
    width: 13vw;
  }

  .main-headline {
    font-family: orator-std, monospace;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    text-align: center;

    @media (max-width: 960px) {
      font-size: 4.9vw;
    }

    @media (min-width: 961px) {
      font-size: 3em;
    }
  }

  .header-link {
    text-decoration: none;
  }
}
