.router-fade-enter,
.router-fade-leave-to {
  opacity: 0;
}

.router-fade-enter-active,
.router-fade-leave-active {
  transition: opacity 300ms;
}

.dom-fade-enter,
.dom-fade-leave-to {
  opacity: 0;
}

.dom-fade-enter-active,
.dom-fade-leave-active {
  transition: opacity 200ms;
}
