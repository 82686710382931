// Breakpoints
$mobile-footer-max: 670px;
$mobile-max: 480px;
$mobile-menu-max: 570px;
$small-screen-max: 1024px;
$tablet-portrait-max: 768px;

// Colours.
$dark: #0E111A;
$error-red: #FF0000;
$white: #FFF;
$white-back: rgba(255, 255, 255, .8);
