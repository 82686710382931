@import './_variables.scss';

h2 {
  color: $dark;
  display: block;
  font-weight: normal;
  letter-spacing: .2em;
  margin: 0 0 1em 0;
  text-align: center;
}

p {
  -webkit-hyphens: auto;
          hyphens: auto;
  text-align: justify;
  text-justify: inter-word;
}
