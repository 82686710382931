@mixin center-absolute() {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin unlist() {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
