@import '@sass/_variables.scss';

.button {
  background-color: $dark;
  border: 0 none;
  color: $white;
  cursor: pointer;
  padding: 1em 1.4em;

  &:disabled {
    cursor: default;
    opacity: .5;
  }
}
