
@import '@sass/_variables.scss';
@import '@sass/_mixins.scss';

.main-nav {

  @media (max-width: $mobile-menu-max) {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }

  @media (min-width: $mobile-menu-max + 1) {
    display: block;
    padding-bottom: 3em;
  }

  .mobile-nav-toggle {

    @media (max-width: $mobile-menu-max) {
      background-color: $white;
      border: 0 none;
      bottom: calc(-40px - 1.6em);
      height: 40px;
      position: absolute;
      right: 1.6em;
      width: 40px;
      z-index: 2;

      svg {
        @include center-absolute;

        fill: $dark;
        height: auto;
        width: 30px;
      }
    }

    @media (min-width: $mobile-menu-max + 1) {
      display: none;
    }

    .open-state {
      visibility: hidden;
    }
  }

  .link-list {
    @include unlist;

    display: flex;

    @media (max-width: $mobile-menu-max) {
      background-color: $white-back;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding: 0 10%;
      position: fixed;
      right: -101%;
      top: 0;
      transition: right 300ms;
      width: 100%;
      z-index: 1;
    }

    @media (min-width: $mobile-menu-max + 1) {
      justify-content: center;
    }

    &.open {

      @media (max-width: $mobile-menu-max) {
        right: 0;
      }
    }
  }

  .nav-link {
    outline: none;
    text-decoration: none;
    text-transform: uppercase;

    @media (max-width: $mobile-menu-max) {
      background-color: $dark;
      border: 0 none;
      color: $white;
      display: block;
      margin-bottom: 2em;
      padding: 1em 1.4em;
    }

    @media (min-width: $mobile-menu-max + 1) {
      display: inline-block;
      margin: 0 1em;
    }

    &.router-link-exact-active {

      @media (max-width: $mobile-menu-max) {
        cursor: default;
        opacity: .5;
      }
    }
  }
}
